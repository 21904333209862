<template>
  <div class="pbf" v-if="props.user">    

    <BrandTabDiaries
      :owner="useAuth().getId() === props.user.id"
      :inurl="'/diaries'"
      :grower-id="props.user.id"      
      :intags="tags"
    />

  </div>
</template>



<script setup lang="ts">

import { TagsGrowerDiaries } from '@/types/other'
import type { User } from '@/types/user'

interface Props {
  user: User;
  id: number;
}

const props = defineProps<Props>();

const tags = ref(structuredClone(TagsGrowerDiaries))

</script>







<style scoped>
 

</style>
